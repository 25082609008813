const SHOPPER = 'Shopper';
const CX = 'CX';

export function identifyUserType(email: string): string {
  if (email.toLowerCase().startsWith(SHOPPER.toLowerCase())) {
    return SHOPPER;
  }

  return CX;
}
