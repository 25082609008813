import { onError } from '@apollo/client/link/error';
import { hermesLogger } from '@common/observability';

import { isExpiredTokenError } from './utils';

export const graphQLErrorsMiddleware = onError(({ graphQLErrors }) => {
  if (graphQLErrors && !isExpiredTokenError(graphQLErrors))
    graphQLErrors.forEach(({ message, locations, path, extensions }) => {
      hermesLogger.error(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
        {
          locations,
          message,
          path,
          extensions,
        }
      );
    });
});
